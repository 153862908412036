/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable at-rule-empty-line-before */
/* stylelint-disable scss/at-rule-conditional-no-parentheses */
/* stylelint-disable color-hex-length */
/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-disable scss/at-function-pattern */
/* stylelint-disable value-keyword-case */

// This file was generated by running 'ng generate @angular/material:theme-color'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #ef4249, secondary: #dd0062, tertiary: #db00d0
$_palettes: (
  primary: (
    0: #000000,
    10: #410006,
    20: #68000f,
    25: #7d0014,
    30: #930019,
    35: #a8021f,
    40: #ba1829,
    50: #de353e,
    60: #ff5357,
    70: #ff8985,
    80: #ffb3b0,
    90: #ffdad8,
    95: #ffedeb,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #3f0017,
    20: #660029,
    25: #7b0033,
    30: #90003d,
    35: #a50047,
    40: #bc0052,
    50: #e71369,
    60: #ff4c83,
    70: #ff85a2,
    80: #ffb1c1,
    90: #ffd9df,
    95: #ffecee,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #390035,
    20: #5c0057,
    25: #6f0069,
    30: #83007c,
    35: #96008f,
    40: #ab00a2,
    50: #d500ca,
    60: #f734e9,
    70: #ff78ed,
    80: #ffabee,
    90: #ffd7f3,
    95: #ffebf7,
    98: #fff7f9,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #271817,
    20: #3e2c2b,
    25: #4a3736,
    30: #564241,
    35: #624d4c,
    40: #6f5958,
    50: #897270,
    60: #a48b89,
    70: #c0a5a3,
    80: #dcc0be,
    90: #f9dcda,
    95: #ffedeb,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
    4: #180a0a,
    6: #1e0f0f,
    12: #2c1b1b,
    17: #372625,
    22: #42302f,
    24: #473534,
    87: #f0d3d1,
    92: #ffe1df,
    94: #ffe9e7,
    96: #fff0ef,
  ),
  neutral-variant: (
    0: #000000,
    10: #2b1615,
    20: #422a29,
    25: #4f3534,
    30: #5b403f,
    35: #684b4a,
    40: #755756,
    50: #8f6f6e,
    60: #ab8987,
    70: #c7a3a1,
    80: #e4bebb,
    90: #ffdad8,
    95: #ffedeb,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);

$primary-palette: map.merge(map.get($_palettes, primary), $_rest);
$tertiary-palette: map.merge(map.get($_palettes, tertiary), $_rest);

@function _high-contrast-value($light, $dark, $theme-type) {
  @if ($theme-type == light) {
    @return $light;
  }
  @if ($theme-type == dark) {
    @return $dark;
  }
  @if ($theme-type == color-scheme) {
    @return light-dark(#{$light}, #{$dark});
  }

  @error 'Unknown theme-type #{$theme-type}. Expected light, dark, or color-scheme';
}

@mixin high-contrast-overrides($theme-type) {
  @include mat.theme-overrides(
    (
      primary: _high-contrast-value(#60000d, #ffecea, $theme-type),
      on-primary: _high-contrast-value(#ffffff, #000000, $theme-type),
      primary-container: _high-contrast-value(#97001a, #ffadaa, $theme-type),
      on-primary-container: _high-contrast-value(#ffffff, #220002, $theme-type),
      inverse-primary: _high-contrast-value(#ffb3b0, #95001a, $theme-type),
      primary-fixed: _high-contrast-value(#97001a, #ffdad8, $theme-type),
      primary-fixed-dim: _high-contrast-value(#6c0010, #ffb3b0, $theme-type),
      on-primary-fixed: _high-contrast-value(#ffffff, #000000, $theme-type),
      on-primary-fixed-variant: _high-contrast-value(#ffffff, #2d0003, $theme-type),
      secondary: _high-contrast-value(#5e0026, #ffebed, $theme-type),
      on-secondary: _high-contrast-value(#ffffff, #000000, $theme-type),
      secondary-container: _high-contrast-value(#94003f, #ffacbc, $theme-type),
      on-secondary-container: _high-contrast-value(#ffffff, #210008, $theme-type),
      secondary-fixed: _high-contrast-value(#94003f, #ffd9df, $theme-type),
      secondary-fixed-dim: _high-contrast-value(#6a002b, #ffb1c1, $theme-type),
      on-secondary-fixed: _high-contrast-value(#ffffff, #000000, $theme-type),
      on-secondary-fixed-variant: _high-contrast-value(#ffffff, #2c000e, $theme-type),
      tertiary: _high-contrast-value(#550050, #ffeaf6, $theme-type),
      on-tertiary: _high-contrast-value(#ffffff, #000000, $theme-type),
      tertiary-container: _high-contrast-value(#870080, #ffa5ed, $theme-type),
      on-tertiary-container: _high-contrast-value(#ffffff, #1d001b, $theme-type),
      tertiary-fixed: _high-contrast-value(#870080, #ffd7f3, $theme-type),
      tertiary-fixed-dim: _high-contrast-value(#60005b, #ffabee, $theme-type),
      on-tertiary-fixed: _high-contrast-value(#ffffff, #000000, $theme-type),
      on-tertiary-fixed-variant: _high-contrast-value(#ffffff, #270024, $theme-type),
      background: _high-contrast-value(#fff8f7, #1e0f0f, $theme-type),
      on-background: _high-contrast-value(#271817, #f9dcda, $theme-type),
      surface: _high-contrast-value(#fff8f7, #1e0f0f, $theme-type),
      surface-dim: _high-contrast-value(#ceb2b1, #1e0f0f, $theme-type),
      surface-bright: _high-contrast-value(#fff8f7, #604b4a, $theme-type),
      surface-container-lowest: _high-contrast-value(#ffffff, #000000, $theme-type),
      surface-container: _high-contrast-value(#f9dcda, #3e2c2b, $theme-type),
      surface-container-high: _high-contrast-value(#ebcecc, #4a3736, $theme-type),
      surface-container-highest: _high-contrast-value(#dcc0be, #564241, $theme-type),
      on-surface: _high-contrast-value(#000000, #ffffff, $theme-type),
      shadow: _high-contrast-value(#000000, #000000, $theme-type),
      scrim: _high-contrast-value(#000000, #000000, $theme-type),
      surface-tint: _high-contrast-value(#ba1829, #ffb3b0, $theme-type),
      inverse-surface: _high-contrast-value(#3e2c2b, #f9dcda, $theme-type),
      inverse-on-surface: _high-contrast-value(#ffffff, #000000, $theme-type),
      outline: _high-contrast-value(#3e2625, #ffecea, $theme-type),
      outline-variant: _high-contrast-value(#5e4241, #dfbab7, $theme-type),
      error: _high-contrast-value(#600004, #ffece9, $theme-type),
      on-error: _high-contrast-value(#ffffff, #000000, $theme-type),
      error-container: _high-contrast-value(#98000a, #ffaea4, $theme-type),
      on-error-container: _high-contrast-value(#ffffff, #220001, $theme-type),
      surface-variant: _high-contrast-value(#ffdad8, #5b403f, $theme-type),
      on-surface-variant: _high-contrast-value(#000000, #ffffff, $theme-type),
    )
  );
}
