/* stylelint-disable font-family-no-missing-generic-family-keyword */

@mixin fonts() {
  // Supports weights 100-900
  @font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url('@fontsource-variable/exo-2/files/exo-2-latin-wght-normal.woff2')
      format('woff2-variations');
    unicode-range:
      U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
      U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 300 600;
    font-display: block;
    src: url('/media/material-symbols.woff2') format('woff2');
  }

  .material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
}
