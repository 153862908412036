@use '@angular/material' as mat;
@use '../utils/colors';

@mixin material() {
  & {
    color-scheme: light dark;

    @include mat.theme(
      (
        color: (
          primary: colors.$primary-palette,
          tertiary: colors.$tertiary-palette,
        ),
        typography: "'Exo 2', sans-serif",
        density: 0,
      )
    );
    @include mat.stepper-overrides(
      (
        container-color: transparent,
      )
    );
    @include mat.expansion-overrides(
      (
        container-background-color: transparent,
      )
    );
    @include mat.toolbar-overrides(
      (
        standard-height: unset,
        mobile-height: unset,
      )
    );

    .mat-mdc-progress-spinner {
      margin: 3rem auto 5rem;
    }

    .mat-expansion-panel-body {
      padding: 0 12px;
    }

    .mat-text-tertiary {
      color: var(--mat-sys-tertiary);
    }

    .mat-text-primary {
      color: var(--mat-sys-primary);
    }

    .cdk-virtual-scroll-content-wrapper {
      width: 100%;
      overflow: hidden;
    }
  }
}
