/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;

@mixin fab() {
  .mdc-fab {
    overflow: hidden;

    > .mdc-button__label {
      opacity: 0;
      max-width: 0;
      transition:
        opacity 240ms var(--mat-sys-motion-emphasized-decelerate) 60ms,
        max-width 240ms var(--mat-sys-motion-emphasized-decelerate) 0ms;
    }
  }

  .mat-mdc-extended-fab > .mdc-button__label {
    opacity: 1;
    max-width: 200px;
  }

  .tertiary-fab {
    @include mat.fab-overrides(
      (
        container-color: var(--mat-sys-tertiary-container),
        small-container-color: var(--mat-sys-tertiary-container),
      )
    );
  }
}
