@use '@angular/material' as mat;

@mixin theme {
  .login {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }

    .mat-horizontal-content-container {
      padding-bottom: 0 !important;
    }
  }
}
