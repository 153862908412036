@use '@angular/material' as mat;

@mixin theme {
  app-member-selection .mat-mdc-form-field-infix {
    display: flex !important;
    align-items: center;
  }

  @media (min-width: theme('screens.md')) {
    app-member-selection .mat-mdc-form-field-infix {
      display: block !important;
    }
  }

  .mat-mdc-option .icon-container {
    color: var(--mat-sys-on-surface);
  }
}
