@use 'base';
@use 'components/all-components';
@use 'utils';
@use 'app/app';
@forward 'vendor';

html {
  @include base.fonts;
  @include base.animations;
  @include base.css-variables;
  @include base.view-transition;
  @include base.material;
  @include base.scrollbar;
  @include base.styles;
  @include all-components.all-components;
  @include app.app;
}
